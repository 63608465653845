import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css'; // Linking the CSS file

const Header = () => {
    return (
        <header className="header">
            <div className="logo-and-title">
                <img src="/logo_v2.png" alt="Open Court Logo" className="logo" />
                <h1 className="site-title">OPEN SCORE</h1>
            </div>
            <nav className="navigation">
                <NavLink to="/" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} end>Home</NavLink>
                <NavLink to="/games" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>Games</NavLink>
                <NavLink to="/players" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>Players</NavLink>
                <NavLink to="/rodman">
                    <button className="pro-button"> RODMAN AI <span></span></button>
                </NavLink>            </nav>
        </header>
    );
};

export default Header;
