// GameList.js
import React, { useState, useEffect } from 'react';
import { fetchGames, fetchPredictions } from './api'; // Ensure these point to your API utility file
import './GameList.css';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import moment from 'moment';

const formatLeagueName = (leagueName) => {
    if (leagueName === 'PremierLeague') {
        return 'Premier League';
    }
    return leagueName;
};

const GameList = () => {
    const [games, setGames] = useState([]);
    const [predictions, setPredictions] = useState([]);
    const [showScores, setShowScores] = useState(true);
    const [showPredictions, setShowPredictions] = useState(true); // New state to toggle predictions

    const defaultFilterDate = process.env.NODE_ENV === 'development' ? '' : new Date().toISOString().split('T')[0];
    const [filterDate, setFilterDate] = useState(defaultFilterDate);
    const [filterTeam, setFilterTeam] = useState('');
    const [filteredGames, setFilteredGames] = useState([]);
    const [filterLeague, setFilterLeague] = useState('');

    useEffect(() => {
        const loadData = async () => {
            const gameData = await fetchGames();
            const startDate = moment().startOf('year').format('YYYY-MM-DD');
            const endDate = moment().endOf('year').format('YYYY-MM-DD');
            const predictionData = await fetchPredictions(startDate, endDate);
            

            if (Array.isArray(gameData)) setGames(gameData);
            if (Array.isArray(predictionData)) setPredictions(predictionData);
        };
        loadData();
    }, []);

    useEffect(() => {
        const filtered = games.filter(game => {
            return (filterDate ? game.date === filterDate : true) &&
                (filterTeam ? game.home_team.toLowerCase().includes(filterTeam.toLowerCase()) ||
                    game.away_team.toLowerCase().includes(filterTeam.toLowerCase()) : true) &&
                (filterLeague ? game.league === filterLeague : true);
        });
        setFilteredGames(filtered);
    }, [games, filterDate, filterTeam, filterLeague]);

    const handleDateChange = (e) => {
        setFilterDate(e.target.value);
    };

    const handleTeamChange = (e) => {
        setFilterTeam(e.target.value);
    };

    const toggleShowScores = () => {
        setShowScores(!showScores);
    };

    const toggleShowPredictions = () => {
        setShowPredictions(!showPredictions);
    };

    const changeDate = (offset) => {
        const currentDate = filterDate ? new Date(filterDate) : new Date();
        currentDate.setDate(currentDate.getDate() + offset);
        setFilterDate(currentDate.toISOString().split('T')[0]);
    };

    // Find a prediction for a specific game
    const findPrediction = (game) => {
        return predictions.find(prediction =>
            prediction.HomeTeam === game.home_team &&
            prediction.AwayTeam === game.away_team &&
            moment(prediction.Date).isSame(game.date, 'day')
        );
    };

    return (
        <div className="game-list-container">
            <h1 className="game-list-title">Games</h1>
            <div className="filters">
                <div className="date-filter">
                    <button className="date-nav-button" onClick={() => changeDate(-1)}>&#60;</button>
                    <input type="date" value={filterDate} onChange={handleDateChange}/>
                    <button className="date-nav-button" onClick={() => changeDate(1)}>&#62;</button>
                </div>

                <input type="text" placeholder="Filter by team..." value={filterTeam} onChange={handleTeamChange}/>
                <button onClick={toggleShowScores}
                        className="score-toggle">{showScores ? 'Hide Scores' : 'Show Scores'}</button>
                {/* <button onClick={toggleShowPredictions}
                        className="score-toggle">{showPredictions ? 'Hide Predictions' : 'Show Predictions'}</button> */}
            </div>
            <div className="league-filter">
                <select value={filterLeague} onChange={e => setFilterLeague(e.target.value)}>
                    <option value="">All Leagues</option>
                    <option value="NBA">NBA</option>
                    <option value="PremierLeague">Premier League</option>
                </select>
            </div>
            {Array.isArray(filteredGames) && filteredGames.length > 0 ? (
                <ul className="list-group game-list-group">
                    {filteredGames.map((game, index) => {
                        const prediction = findPrediction(game);
                        return (
                            <li key={index} className="list-group-item game-list-group-item">
                                <div className="score-container">
                                    <span className="team-name">{game.home_team}</span>
                                    {showScores ? (
                                        <span className="game-score">{game.home_score} - {game.away_score}</span>
                                    ) : (
                                        <span className="game-score-hidden"></span>
                                    )}
                                    {prediction && (
                                        <span className="predicted-score">
                                            Predicted: {prediction.PredictedHomeScore} - {prediction.PredictedAwayScore}
                                        </span>
                                    )}
                                    <span className="team-name">{game.away_team}</span>
                                    <span className="game-date">{game.date}</span>
                                    <span className="game-location">{game.venue}</span>
                                    <span className="league-badge">{formatLeagueName(game.league)}</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p>No games to display.</p>
            )}
        </div>
    );
};

export default GameList;
