import React from 'react';
import './Home.css'; // Link to the updated CSS file

const Home = () => {
    return (
        <div className="home-container">
        <img src="/logo_v2.png" alt="Open Score Logo" className="home-logo" />
            <h1>Welcome to Open Score</h1>
            <p>
                Open Score is a dynamic platform dedicated to sports enthusiasts, providing in-depth player statistics, game logs, and video highlights. Our goal is to offer sports analysts and fans an engaging and informative experience, combining statistical analysis with rich multimedia content. Whether you're following your favorite team or keeping up with the latest in sports, Open Score has everything you need to stay informed and entertained.
            </p>
        </div>
    );
};

export default Home;
