import React, { useState, useEffect } from 'react';
import './PlayerList.css';
import { fetchPlayers } from './api'; // Ensure this points to your API utility file

const PlayerList = () => {
    const [players, setPlayers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const loadPlayers = async () => {
            const playersData = await fetchPlayers();
            if (Array.isArray(playersData)) {
                setPlayers(playersData);
            }
        };

        loadPlayers();
    }, []);

    const filteredPlayers = players.filter(player => {
        const query = searchQuery.toLowerCase();
        return (
            player.team.toLowerCase().includes(query) ||
            player.nationality.toLowerCase().includes(query) ||
            player.position.toLowerCase().includes(query) ||
            player.name.toLowerCase().includes(query)
        );
    });

    return (
        <div className="player-list-container">
            <h2>Player Profiles</h2>
            <div className="filters">
                <input
                    type="text"
                    placeholder="Search by keyword"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    className="search-bar"
                    size="30"
                />
            </div>
            <div className="player-cards">
                {filteredPlayers.map(player => (
                    <div key={player.id} className="player-card">
                        <div className="player-header">
                            <h3>{player.name}</h3>
                            <p className="player-team">{player.team}</p>
                        </div>
                        <div className="player-body">
                            <p><strong>Nationality:</strong> {player.nationality}</p>
                            <p><strong>Position:</strong> {player.position}</p>
                            <p><strong>Date of Birth:</strong> {player.date_of_birth}</p>
                        </div>
                    </div>
                ))}
            </div>
            {filteredPlayers.length === 0 && <p>No players to display.</p>}
        </div>
    );
};

export default PlayerList;
