import React from 'react';
import './Rodman.css'; // Make sure to link to the Rodman CSS file

const Rodman = () => {
    return (
        <div className="rodman-container">
            <h2>RODMAN: The Future of Sports Analytics</h2>
            <p>
                RODMAN is the next evolution in sports analysis, empowering sports enthusiasts with cutting-edge analytics and insights. By synthesizing complex data with advanced AI, RODMAN provides unparalleled statistical depth, game logs, and video highlights. Designed for both analysts and fans, RODMAN promises to enhance your sports experience with rich, engaging content tailored to your needs. Stay ahead of the game with RODMAN's intelligent insights and features.
            </p>
            <div className="features">
                <div className="feature">Advanced Analytics</div>
                <div className="feature">Real-Time Stats</div>
                <div className="feature">Player Performance Prediction</div>
                <div className="feature">Game Outcome Forecasts</div>
            </div>
<a href="https://www.linkedin.com/in/puk0de/" target="_blank" className="notify-button">Connect for RODMAN Updates</a>
        </div>
    );
};

export default Rodman;
