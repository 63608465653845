import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home'; // Import the Home component
import GameList from './GameList';
import PlayerList from './PlayerList';
import Rodman from './Rodman';
import Header from './Header';
import './App.css';
import ReactGA from 'react-ga';

ReactGA.initialize('G-ELYCEFYTSR');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} /> {/* Home route */}
                        <Route path="/games" element={<GameList />} />
                        <Route path="/players" element={<PlayerList />} />
                        <Route path="/rodman" element={<Rodman />} />
                        {/* Add more routes as needed */}
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;